/* Typography */
@use "common";
@use "sass:math";

@mixin typo-info($font-family: common.$font-family, $font-weight: common.$font-weight-regular, $font-size, $line-height) {
  font-family: $font-family;
  font-weight: $font-weight;
  font-size: $font-size;
  line-height: $line-height;
}

%h1 {
  @include typo-info($font-size: common.$font-size-h1, $line-height: common.$font-line-height-h1);
}

%h2 {
  @include typo-info($font-size: common.$font-size-h2, $line-height: common.$font-line-height-h2);
}

%h3 {
  @include typo-info($font-size: common.$font-size-h3, $line-height: common.$font-line-height-h3);
}

%h4 {
  @include typo-info($font-size: common.$font-size-h4, $line-height: common.$font-line-height-h4);
}

%h5 {
  @include typo-info($font-size: common.$font-size-h5, $line-height: common.$font-line-height-h5);
}

%h5-semibold {
  @include typo-info($font-weight: common.$font-weight-semibold, $font-size: common.$font-size-h5, $line-height: common.$font-line-height-h5);
}

%h6 {
  @include typo-info($font-size: common.$font-size-h6, $line-height: common.$font-line-height-h6);
}

%body-1 {
  @include typo-info($font-size: common.$font-size-body-1, $line-height: common.$font-line-height-body-1);
}

%body-1-underline {
  @include typo-info($font-size: common.$font-size-body-1, $line-height: common.$font-line-height-body-1);
  text-decoration-line: underline;
}

%body-1-semibold {
  @include typo-info($font-weight: common.$font-weight-semibold, $font-size: common.$font-size-body-1, $line-height: common.$font-line-height-body-1);
}

%body-1-semibold-underline {
  @include typo-info($font-weight: common.$font-weight-semibold, $font-size: common.$font-size-body-1, $line-height: common.$font-line-height-body-1);
  text-decoration-line: underline;
}

%body-2 {
  @include typo-info($font-size: common.$font-size-body-2, $line-height: common.$font-line-height-body-2);
}

%body-2-semibold {
  @include typo-info($font-weight: common.$font-weight-semibold, $font-size: common.$font-size-body-2, $line-height: common.$font-line-height-body-2);
}

%body-3 {
  @include typo-info($font-size: common.$font-size-body-3, $line-height: common.$font-line-height-body-3);
}

%body-3-semibold {
  @include typo-info($font-weight: common.$font-weight-semibold, $font-size: common.$font-size-body-3, $line-height: common.$font-line-height-body-3);
}

%overline {
  @include typo-info($font-weight: common.$font-weight-lighter, $font-size: common.$font-size-body-2, $line-height: common.$font-line-height-overline-label);
  letter-spacing: .09em;
}

%overline-semibold {
  @include typo-info($font-weight: common.$font-weight-semibold, $font-size: common.$font-size-body-2, $line-height: common.$font-line-height-overline-label);
}

%label {
  @include typo-info($font-size: common.$font-size-body-2, $line-height: common.$font-line-height-overline-label);
}

%uom {
  @include typo-info($font-size: common.$font-size-body-3, $line-height: common.$font-line-height-uom);
}

%uom-semibold {
  @include typo-info($font-weight: common.$font-weight-semibold, $font-size: common.$font-size-body-3, $line-height: common.$font-line-height-uom);
}
