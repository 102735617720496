@use "theme-variables" as variables;
@use "common";

.theme-dark {
  /* UI */
  #{variables.$ui-background}: #{common.$black};
  #{variables.$ui-section}: #{common.$grey-12};
  #{variables.$ui-data-entry}: #{common.$grey-95};
  #{variables.$ui-data-fill}: #{common.$grey-20};
  #{variables.$ui-tab-underline}: #{common.$grey-60};
  #{variables.$ui-divider}: #{common.$grey-40};
  #{variables.$ui-inverse}: #{common.$grey-100};
  #{variables.$ui-curtain}: #{common.$black-80p};
  #{variables.$ui-modal}: #{common.$grey-20};
  #{variables.$ui-popup}: #{common.$grey-20-90p};

  /* Text */
  #{variables.$text-primary}: #{common.$grey-100};
  #{variables.$text-secondary}: #{common.$grey-95};
  #{variables.$text-placeholder}: #{common.$grey-90};
  #{variables.$text-interactive}: #{common.$white};
  #{variables.$text-tertiary}: #{common.$grey-70};
  #{variables.$text-caution}: #{common.$yellow};
  #{variables.$text-error}: #{common.$red-90};
  #{variables.$text-inverse}: #{common.$black};

  /* Icon */
  #{variables.$icon-primary}: #{common.$grey-100};
  #{variables.$icon-interactive}: #{common.$grey-100};
  #{variables.$icon-secondary}: #{common.$grey-95};
  #{variables.$icon-tertiary}: #{common.$blue-80};
  #{variables.$icon-inverse}: #{common.$grey-0};
  #{variables.$icon-notam-pending}: #{common.$orange};
  #{variables.$icon-notam-active}: #{common.$red-50};
  #{variables.$icon-map-magenta}: #{common.$magenta};
  #{variables.$icon-map-cyan}: #{common.$cyan};
  #{variables.$icon-obstacle-notam}: #{common.$magenta-80};

  /* Status */
  #{variables.$info}: #{common.$blue-80};
  #{variables.$info-bg}: #{common.$blue-20};
  #{variables.$success}: #{common.$green-80};
  #{variables.$success-bg}: #{common.$green-20};
  #{variables.$caution}: #{common.$yellow};
  #{variables.$caution-bg}: #{common.$yellow-20};
  #{variables.$error}: #{common.$red-80};
  #{variables.$error-bg}: #{common.$red-20};
  #{variables.$vfr}: #{common.$green};
  #{variables.$mvfr}: #{common.$blue};
  #{variables.$ifr}: #{common.$red};
  #{variables.$lifr}: #{common.$magenta-80};
  #{variables.$no-wx}: #{common.$grey-50};
  #{variables.$success-ui}: #{common.$green-40};
  #{variables.$banner-content-success}: #{common.$green-99};
  #{variables.$info-ui}: #{common.$blue-40};
  #{variables.$banner-content-info}: #{common.$blue-99};
  #{variables.$caution-ui}: #{common.$yellow-80};
  #{variables.$banner-content-caution}: #{common.$yellow};
  #{variables.$error-ui}: #{common.$red-40};
  #{variables.$banner-content-error}: #{common.$red-99};
  #{variables.$pending-ui}: #{common.$orange-60};

  /* Interactive */
  #{variables.$interactive-primary}: #{common.$blue};
  #{variables.$interactive-secondary}: #{common.$grey-40};
  #{variables.$interactive-danger}: #{common.$red-60};
  #{variables.$interactive-danger-ghost}: #{common.$red-70};
  #{variables.$interactive-ui}: #{common.$grey-12};
  #{variables.$link-primary}: #{common.$blue-80};
  #{variables.$link-inverse}: #{common.$blue};
  #{variables.$interactive-map}: #{common.$grey-0-90p};
  #{variables.$interactive-tertiary}: #{common.$blue-80};
  #{variables.$interactive-ghost}: #{common.$grey-100};
  #{variables.$interactive-background}: #{common.$grey-20};
  #{variables.$interactive-border}: #{common.$grey-70};

  /* Hover */
  #{variables.$hover-primary}: #{common.$blue-50};
  #{variables.$hover-secondary}: #{common.$grey-50};
  #{variables.$hover-danger}: #{common.$red-50};
  #{variables.$hover-ui}: #{common.$grey-20};
  #{variables.$hover-link}: #{common.$blue-90};
  #{variables.$hover-link-inverse}: #{common.$blue-50};
  #{variables.$hover-map}: #{common.$grey-10-90p};
  #{variables.$hover-border}: #{common.$grey-70};
  #{variables.$hover-data-fill}: #{common.$grey-30};
  #{variables.$hover-tertiary}: #{common.$blue-90};

  /* Press */
  #{variables.$press-primary}: #{common.$blue-40};
  #{variables.$press-secondary}: #{common.$grey-60};
  #{variables.$press-danger}: #{common.$red-40};
  #{variables.$press-ui}: #{common.$grey-30};
  #{variables.$press-link}: #{common.$blue-95};
  #{variables.$press-link-inverse}: #{common.$blue-40};
  #{variables.$press-map}: #{common.$grey-20-90p};
  #{variables.$press-border}: #{common.$grey-80};
  #{variables.$press-tertiary}: #{common.$blue-95};
  #{variables.$press-ghost}: #{common.$grey-90};

  /* Selected */
  #{variables.$selected-primary}: #{common.$blue};
  #{variables.$selected-secondary}: #{common.$grey-40};
  #{variables.$selected-tertiary}: #{common.$grey-70};
  #{variables.$selected-ui}: #{common.$grey-30};
  #{variables.$selected-calendar}: #{common.$blue-30};

  /* Disabled */
  #{variables.$disabled-01}: #{common.$grey-20};
  #{variables.$disabled-02}: #{common.$grey-40};
  #{variables.$disabled-03}: #{common.$grey-60};
  #{variables.$disabled-toggle}: #{common.$blue-20};
  #{variables.$disabled-map}: #{common.$grey-0-90p};

  /* Transparent */
  #{variables.$transparent}: #{common.$transparent};

  /* Focus */
  #{variables.$focus}: #{common.$white};

  /* Weather */
  #{variables.$vfr}: #{common.$green-60};
  #{variables.$mvfr}: #{common.$blue-60};
  #{variables.$ifr}: #{common.$red-60};
  #{variables.$lifr}: #{common.$magenta-80};
}
