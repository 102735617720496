@use "theme-variables" as theme;
@use "common";
@use "typography";
@use "breakpoints";

@mixin fly-card($padding: 0) {
  display: flex;
  color: var(theme.$text-primary);
  background: var(theme.$interactive-ui);
  padding: $padding;
  justify-content: space-between;

  &:hover {
    background: var(theme.$hover-ui);
  }

  &:active {
    background: var(common.$grey-20);
  }

  &:focus-visible {
    @include focus();
  }
}

@mixin focus() {
  box-shadow: 0 0 0 2px var(theme.$focus);

  outline: transparent solid .125rem;
  outline-offset: -.125rem;
}

@mixin link() {
  font-weight: 400;
  color: var(theme.$link-primary);
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: var(theme.$link-primary);
    text-decoration: underline;
  }

  &:active {
    color: var(theme.$press-link);
  }

  &.link-inverse {
    color: var(theme.$link-inverse);

    &:hover {
      color: var(theme.$hover-primary);
    }

    &:active {
      color: var(theme.$press-primary);
    }
  }

  &:focus-visible {
    outline: var(theme.$focus) solid common.$spacing-01;
    outline-offset: -#{common.$spacing-01};
  }
}

@mixin table() {
  table-layout: fixed;
  width: 100%;

  thead {
    background-color: var(theme.$interactive-ui);
  }

  td,
  th {
    padding: .5rem 1rem;
    vertical-align: middle;
  }

  tbody {
    tr {
      border-top: 1px solid #353535;
      background-color: var(theme.$ui-section);
    }
  }

  caption {
    visibility: hidden;
    height: 0;
    width: 0;
  }

  @media screen and (max-width: breakpoints.$sm-breakpoint) {
    table {
      table-layout: auto;
    }
  }
}
