// This is derived from maplibre-gl.css in MapLibre 4.4.0.
// It basically removes stuff we're not using and modifies some stuff.

/* stylelint-disable */

.maplibregl-map {
  font: 12px/20px "Helvetica Neue", Arial, Helvetica, sans-serif;
  overflow: hidden;
  position: relative;
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

// Overriding the font-family
.maplibregl-map {
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.maplibregl-canvas {
  position: absolute;
  left: 0;
  top: 0;
}

.maplibregl-canvas-container.maplibregl-interactive {
  cursor: grab;
  user-select: none;
}

.maplibregl-canvas-container.maplibregl-interactive:active {
  cursor: grabbing;
}

.maplibregl-canvas-container.maplibregl-touch-zoom-rotate,
.maplibregl-canvas-container.maplibregl-touch-zoom-rotate .maplibregl-canvas {
  touch-action: pan-x pan-y;
}

.maplibregl-canvas-container.maplibregl-touch-drag-pan,
.maplibregl-canvas-container.maplibregl-touch-drag-pan .maplibregl-canvas {
  touch-action: pinch-zoom;
}

.maplibregl-canvas-container.maplibregl-touch-zoom-rotate.maplibregl-touch-drag-pan,
.maplibregl-canvas-container.maplibregl-touch-zoom-rotate.maplibregl-touch-drag-pan .maplibregl-canvas {
  touch-action: none;
}

.maplibregl-canvas-container.maplibregl-touch-drag-pan.maplibregl-cooperative-gestures,
.maplibregl-canvas-container.maplibregl-touch-drag-pan.maplibregl-cooperative-gestures .maplibregl-canvas {
  touch-action: pan-x pan-y;
}

.maplibregl-marker {
  position: absolute;
  top: 0;
  left: 0;
  will-change: transform;
  transition: opacity 0.2s;
}

.maplibregl-boxzoom {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  background: #fff;
  border: 2px dotted #202020;
  opacity: 0.5;
}

.maplibregl-cooperative-gesture-screen {
  background: rgba(0 0 0 / 40%);
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  padding: 1rem;
  font-size: 1.4em;
  line-height: 1.2;
  opacity: 0;
  pointer-events: none;
  transition: opacity 1s ease 1s;
  z-index: 99999;
}

.maplibregl-cooperative-gesture-screen.maplibregl-show {
  opacity: 1;
  transition: opacity 0.05s;
}

.maplibregl-cooperative-gesture-screen .maplibregl-mobile-message {
  display: none;
}

@media (hover: none), (width <= 480px) {
  .maplibregl-cooperative-gesture-screen .maplibregl-desktop-message {
      display: none;
  }

  .maplibregl-cooperative-gesture-screen .maplibregl-mobile-message {
      display: block;
  }
}

.maplibregl-pseudo-fullscreen {
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 99999;
}

/* stylelint-enable */
