$dark: theme-dark !default;
$gray: theme-gray !default;

/* UI */

$ui-background: --ui-background !default;
$ui-section: --ui-section !default;
$ui-data-entry: --ui-data-entry !default;
$ui-data-fill: --ui-data-fill !default;
$ui-tab-underline: --ui-tab-underline !default;
$ui-divider: --ui-divider !default;
$ui-inverse: --ui-inverse !default;
$ui-curtain: --ui-curtain !default;
$ui-modal: --ui-modal !default;
$ui-popup: --ui-popup !default;

/* Text */

$text-primary: --text-primary !default;
$text-secondary: --text-secondary !default;
$text-placeholder: --text-placeholder !default;
$text-interactive: --text-interactive !default;
$text-tertiary: --text-tertiary !default;
$text-caution: --text-caution !default;
$text-error: --text-error !default;
$text-inverse: --text-inverse !default;

/* Icon */

$icon-primary: --icon-primary !default;
$icon-interactive: --icon-interactive !default;
$icon-secondary: --icon-secondary !default;
$icon-tertiary: --icon-tertiary !default;
$icon-inverse: --icon-inverse !default;
$icon-notam-pending: --icon-notam-pending !default;
$icon-notam-active: --icon-notam-active !default;
$icon-map-magenta: --icon-map-magenta !default;
$icon-map-cyan: --icon-map-cyan !default;
$icon-obstacle-notam: --icon-obstacle-notam !default;

/* Status */

$info: --info !default;
$info-bg: --info-bg !default;
$success: --success !default;
$success-bg: --success-bg !default;
$caution: --caution !default;
$caution-bg: --caution-bg !default;
$error: --error !default;
$error-bg: --error-bg !default;
$vfr: --vfr !default;
$mvfr: --mvfr !default;
$ifr: --ifr !default;
$lifr: --lifr !default;
$no-wx: --no-wx !default;
$success-ui: --success-ui !default;
$banner-content-success: --banner-content-success !default;
$info-ui: --info-ui !default;
$banner-content-info: --banner-content-info !default;
$caution-ui: --caution-ui !default;
$banner-content-caution: --banner-content-caution !default;
$error-ui: --error-ui !default;
$banner-content-error: --banner-content-error !default;
$pending-ui: --pending-ui !default;

/* Interactive */

$interactive-primary: --interactive-primary !default;
$interactive-secondary: --interactive-secondary !default;
$interactive-danger: --interactive-danger !default;
$interactive-danger-ghost: --interactive-danger-ghost !default;
$interactive-ui: --interactive-ui !default;
$link-primary: --link-primary !default;
$link-inverse: --link-inverse !default;
$interactive-map: --interactive-map !default;
$interactive-tertiary: --interactive-tertiary !default;
$interactive-ghost: --interactive-ghost !default;
$interactive-background: --interactive-background !default;
$interactive-border: --interactive-border !default;

/* Hover */

$hover-primary: --hover-primary !default;
$hover-secondary: --hover-secondary !default;
$hover-danger: --hover-danger !default;
$hover-ui: --hover-ui !default;
$hover-link: --hover-link !default;
$hover-link-inverse: --hover-link-inverse !default;
$hover-map: --hover-map !default;
$hover-border: --hover-border !default;
$hover-data-fill: --hover-data-fill !default;
$hover-tertiary: --hover-tertiary !default;

/* Press */

$press-primary: --press-primary !default;
$press-secondary: --press-secondary !default;
$press-danger: --press-danger !default;
$press-ui: --press-ui !default;
$press-link: --press-link !default;
$press-link-inverse: --press-link-inverse !default;
$press-map: --press-map !default;
$press-border: --press-border !default;
$press-tertiary: --press-tertiary !default;
$press-ghost: --press-ghost !default;

/* Selected */

$selected-primary: --selected-primary !default;
$selected-secondary: --selected-secondary !default;
$selected-tertiary: --selected-tertiary !default;
$selected-ui: --selected-ui !default;
$selected-calendar: --selected-calendar !default;

/* Disabled */

$disabled-01: --disabled-01 !default;
$disabled-02: --disabled-02 !default;
$disabled-03: --disabled-03 !default;
$disabled-toggle: --disabled-toggle !default;
$disabled-map: --disabled-map !default;

/* Focus */
$focus: --focus !default;

/* Transparent */
$transparent: --transparent !default;
