$transparent: #0000 !default; // generated from _common.json, do not modify here
$blue: #0066f5 !default; // generated from _common.json, do not modify here
$blue-logo: #047cc1 !default; // generated from _common.json, do not modify here
$blue-0: #000023 !default; // generated from _common.json, do not modify here
$blue-10: #000349 !default; // generated from _common.json, do not modify here
$blue-20: #000e70 !default; // generated from _common.json, do not modify here
$blue-30: #001f96 !default; // generated from _common.json, do not modify here
$blue-40: #0033b9 !default; // generated from _common.json, do not modify here
$blue-50: #0049d6 !default; // generated from _common.json, do not modify here
$blue-60: #0062ef !default; // generated from _common.json, do not modify here
$blue-70: #297bff !default; // generated from _common.json, do not modify here
$blue-80: #4d95ff !default; // generated from _common.json, do not modify here
$blue-90: #72aeff !default; // generated from _common.json, do not modify here
$blue-95: #98c7ff !default; // generated from _common.json, do not modify here
$blue-99: #c1deff !default; // generated from _common.json, do not modify here
$blue-100: #ebf5ff !default; // generated from _common.json, do not modify here
$green: #119637 !default; // generated from _common.json, do not modify here
$green-0: #000900 !default; // generated from _common.json, do not modify here
$green-10: #001c00 !default; // generated from _common.json, do not modify here
$green-20: #003100 !default; // generated from _common.json, do not modify here
$green-30: #004600 !default; // generated from _common.json, do not modify here
$green-40: #005c00 !default; // generated from _common.json, do not modify here
$green-50: #00720b !default; // generated from _common.json, do not modify here
$green-60: #008728 !default; // generated from _common.json, do not modify here
$green-70: #2d9c44 !default; // generated from _common.json, do not modify here
$green-80: #55b062 !default; // generated from _common.json, do not modify here
$green-90: #7ac482 !default; // generated from _common.json, do not modify here
$green-95: #9fd6a4 !default; // generated from _common.json, do not modify here
$green-99: #c6e7c8 !default; // generated from _common.json, do not modify here
$green-100: #edf8ed !default; // generated from _common.json, do not modify here
$yellow: #f1c21b !default; // generated from _common.json, do not modify here
$yellow-0: #0d0300 !default; // generated from _common.json, do not modify here
$yellow-10: #220d00 !default; // generated from _common.json, do not modify here
$yellow-20: #391c00 !default; // generated from _common.json, do not modify here
$yellow-30: #502c00 !default; // generated from _common.json, do not modify here
$yellow-40: #673e00 !default; // generated from _common.json, do not modify here
$yellow-50: #7e5200 !default; // generated from _common.json, do not modify here
$yellow-60: #936700 !default; // generated from _common.json, do not modify here
$yellow-70: #a77d00 !default; // generated from _common.json, do not modify here
$yellow-80: #ba9404 !default; // generated from _common.json, do not modify here
$yellow-90: #cbac51 !default; // generated from _common.json, do not modify here
$yellow-95: #dbc484 !default; // generated from _common.json, do not modify here
$yellow-99: #eadcb5 !default; // generated from _common.json, do not modify here
$yellow-100: #f8f4e7 !default; // generated from _common.json, do not modify here
$orange: #ffa500 !default; // generated from _common.json, do not modify here
$orange-0: #110100 !default; // generated from _common.json, do not modify here
$orange-10: #2b0400 !default; // generated from _common.json, do not modify here
$orange-20: #450e00 !default; // generated from _common.json, do not modify here
$orange-30: #601c00 !default; // generated from _common.json, do not modify here
$orange-40: #7a2d00 !default; // generated from _common.json, do not modify here
$orange-50: #934100 !default; // generated from _common.json, do not modify here
$orange-60: #aa5600 !default; // generated from _common.json, do not modify here
$orange-70: #be6e00 !default; // generated from _common.json, do not modify here
$orange-80: #d08711 !default; // generated from _common.json, do not modify here
$orange-90: #dfa154 !default; // generated from _common.json, do not modify here
$orange-95: #ebbc85 !default; // generated from _common.json, do not modify here
$orange-99: #f5d7b6 !default; // generated from _common.json, do not modify here
$orange-100: #fcf2e8 !default; // generated from _common.json, do not modify here
$magenta: #ff00ff !default; // generated from _common.json, do not modify here
$magenta-0: #170019 !default; // generated from _common.json, do not modify here
$magenta-10: #340038 !default; // generated from _common.json, do not modify here
$magenta-20: #530058 !default; // generated from _common.json, do not modify here
$magenta-30: #720077 !default; // generated from _common.json, do not modify here
$magenta-40: #900095 !default; // generated from _common.json, do not modify here
$magenta-50: #ac00b0 !default; // generated from _common.json, do not modify here
$magenta-60: #c500c7 !default; // generated from _common.json, do not modify here
$magenta-70: #da00db !default; // generated from _common.json, do not modify here
$magenta-80: #eb3eea !default; // generated from _common.json, do not modify here
$magenta-90: #f870f5 !default; // generated from _common.json, do not modify here
$magenta-95: #ff9bfc !default; // generated from _common.json, do not modify here
$magenta-99: #ffc5ff !default; // generated from _common.json, do not modify here
$magenta-100: #ffedff !default; // generated from _common.json, do not modify here
$cyan: #00ffff !default; // generated from _common.json, do not modify here
$cyan-0: #00090b !default; // generated from _common.json, do not modify here
$cyan-10: #001c1e !default; // generated from _common.json, do not modify here
$cyan-20: #003134 !default; // generated from _common.json, do not modify here
$cyan-30: #004649 !default; // generated from _common.json, do not modify here
$cyan-40: #005c5f !default; // generated from _common.json, do not modify here
$cyan-50: #007275 !default; // generated from _common.json, do not modify here
$cyan-60: #00888a !default; // generated from _common.json, do not modify here
$cyan-70: #009d9e !default; // generated from _common.json, do not modify here
$cyan-80: #00b1b2 !default; // generated from _common.json, do not modify here
$cyan-90: #48c4c4 !default; // generated from _common.json, do not modify here
$cyan-95: #84d7d6 !default; // generated from _common.json, do not modify here
$cyan-99: #b7e8e7 !default; // generated from _common.json, do not modify here
$red: #da1e28 !default; // generated from _common.json, do not modify here
$red-0: #190000 !default; // generated from _common.json, do not modify here
$red-10: #380000 !default; // generated from _common.json, do not modify here
$red-20: #590000 !default; // generated from _common.json, do not modify here
$red-30: #7a0000 !default; // generated from _common.json, do not modify here
$red-40: #990000 !default; // generated from _common.json, do not modify here
$red-50: #b60000 !default; // generated from _common.json, do not modify here
$red-60: #cf0c1f !default; // generated from _common.json, do not modify here
$red-70: #e43d3c !default; // generated from _common.json, do not modify here
$red-80: #f5625b !default; // generated from _common.json, do not modify here
$red-90: #ff857c !default; // generated from _common.json, do not modify here
$red-95: #ffa89f !default; // generated from _common.json, do not modify here
$red-99: #ffccc5 !default; // generated from _common.json, do not modify here
$red-100: #ffefec !default; // generated from _common.json, do not modify here
$cyan-100: #e9f8f7 !default; // generated from _common.json, do not modify here
$black: #000000 !default; // generated from _common.json, do not modify here
$black-80p: rgba(0, 0, 0, 0.8000) !default; // generated from _common.json, do not modify here
$black-70p: rgba(0, 0, 0, 0.7000) !default; // generated from _common.json, do not modify here
$grey-0: #050505 !default; // generated from _common.json, do not modify here
$grey-0-90p: rgba(5, 5, 5, 0.9000) !default; // generated from _common.json, do not modify here
$grey-10: #131313 !default; // generated from _common.json, do not modify here
$grey-12: #171717 !default; // generated from _common.json, do not modify here
$grey-10-90p: rgba(19, 19, 19, 0.9000) !default; // generated from _common.json, do not modify here
$grey-20: #232323 !default; // generated from _common.json, do not modify here
$grey-20-90p: rgba(35, 35, 35, 0.9000) !default; // generated from _common.json, do not modify here
$grey-30: #353535 !default; // generated from _common.json, do not modify here
$grey-40: #474747 !default; // generated from _common.json, do not modify here
$grey-50: #5b5b5b !default; // generated from _common.json, do not modify here
$grey-60: #6f6f6f !default; // generated from _common.json, do not modify here
$grey-70: #848484 !default; // generated from _common.json, do not modify here
$grey-80: #999999 !default; // generated from _common.json, do not modify here
$grey-90: #afafaf !default; // generated from _common.json, do not modify here
$grey-95: #c5c5c5 !default; // generated from _common.json, do not modify here
$grey-99: #dcdcdc !default; // generated from _common.json, do not modify here
$grey-99-90p: rgba(220, 220, 220, 0.9000) !default; // generated from _common.json, do not modify here
$grey-100: #f4f4f4 !default; // generated from _common.json, do not modify here
$grey-100-90p: rgba(244, 244, 244, 0.9000) !default; // generated from _common.json, do not modify here
$white: #ffffff !default; // generated from _common.json, do not modify here
$white-90p: rgba(255, 255, 255, 0.9000) !default; // generated from _common.json, do not modify here
$spacing-01: .125rem !default; // generated from _common.json, do not modify here
$spacing-02: .25rem !default; // generated from _common.json, do not modify here
$spacing-03: .5rem !default; // generated from _common.json, do not modify here
$spacing-04: .75rem !default; // generated from _common.json, do not modify here
$spacing-05: 1rem !default; // generated from _common.json, do not modify here
$spacing-06: 1.5rem !default; // generated from _common.json, do not modify here
$spacing-07: 2rem !default; // generated from _common.json, do not modify here
$spacing-08: 2.5rem !default; // generated from _common.json, do not modify here
$spacing-09: 3rem !default; // generated from _common.json, do not modify here
$spacing-10: 4rem !default; // generated from _common.json, do not modify here
$spacing-11: 5rem !default; // generated from _common.json, do not modify here
$spacing-12: 6rem !default; // generated from _common.json, do not modify here
$spacing-13: 10rem !default; // generated from _common.json, do not modify here
$font-family: roboto !default; // generated from _common.json, do not modify here
$font-weight-lighter: 300 !default; // generated from _common.json, do not modify here
$font-weight-regular: 400 !default; // generated from _common.json, do not modify here
$font-weight-semibold: 600 !default; // generated from _common.json, do not modify here
$font-size-h1: 2.75rem !default; // generated from _common.json, do not modify here
$font-size-h2: 2rem !default; // generated from _common.json, do not modify here
$font-size-h3: 1.625rem !default; // generated from _common.json, do not modify here
$font-size-h4: 1.375rem !default; // generated from _common.json, do not modify here
$font-size-h5: 1.125rem !default; // generated from _common.json, do not modify here
$font-size-h6: 1rem !default; // generated from _common.json, do not modify here
$font-size-body-1: .875rem !default; // generated from _common.json, do not modify here
$font-size-body-2: .75rem !default; // generated from _common.json, do not modify here
$font-size-body-3: .625rem !default; // generated from _common.json, do not modify here
$font-size-footer: .625rem !default; // generated from _common.json, do not modify here
$font-size-input-label: .75rem !default; // generated from _common.json, do not modify here
$font-line-height-h1: 2.5625rem !default; // generated from _common.json, do not modify here
$font-line-height-h2: 2.125rem !default; // generated from _common.json, do not modify here
$font-line-height-h3: 1.75rem !default; // generated from _common.json, do not modify here
$font-line-height-h4: 1.5625rem !default; // generated from _common.json, do not modify here
$font-line-height-h5: 1.5625rem !default; // generated from _common.json, do not modify here
$font-line-height-h6: 1.438rem !default; // generated from _common.json, do not modify here
$font-line-height-body-1: 1.313rem !default; // generated from _common.json, do not modify here
$font-line-height-body-2: 1.25rem !default; // generated from _common.json, do not modify here
$font-line-height-body-3: 1rem !default; // generated from _common.json, do not modify here
$font-line-height-input-label: 1.125rem !default; // generated from _common.json, do not modify here
$font-line-height-overline-label: 1.125rem !default; // generated from _common.json, do not modify here
$font-line-height-uom: .75rem !default; // generated from _common.json, do not modify here
