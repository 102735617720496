@use "theme-variables" as variables;
@use "common";

/* These colors are based on the Carbon V10 grey-10 Design System. */

// TODO: THEME GREY UNSUPPORTED FOR NOW, WILL RE-ADD ONCE DUST SETTLES ON THEME-DARK 072224
// .theme-grey {
//   /* UI */
//   #{variables.$ui-background}: #{common.$grey-12}; /* Browser background, lowest layer */
//   #{variables.$ui-section}: #{common.$white}; /* Default section background */
//   #{variables.$interactive-ui}: #{common.$grey-20}; /* Container for interactive elements */
//   #{variables.$ui-data-entry}: #{common.$grey-30}; /* Interactive border; */
//   #{variables.$ui-03}: #{common.$grey-80}; /* Not currently used */
//   #{variables.$ui-04}: #{common.$grey-50}; /* Not currently used */
//   #{variables.$ui-inverse}: #{common.$grey-90}; /* Tooltip container */
//   #{variables.$ui-curtain}: #{common.$black}; /* Modal Curtain */
//   #{variables.$interactive-map}: #{common.$grey-90-90p}; /* Map Controls */
//   #{variables.$decorative-01}: #{common.$grey-70}; /* “Light” variant elements */

//   /* Text */
//   #{variables.$text-primary}: #{common.$grey-100}; /* Primary text; Body copy; Headers; Hover text color for text-secondary */
//   #{variables.$text-secondary}: #{common.$grey-70}; /* Secondary text; Input labels */
//   #{variables.$text-tertiary}: #{common.$grey-40}; /* Tertiary text; Help text */
//   #{variables.$text-placeholder}: #{common.$grey-40}; /* Placeholder text */
//   #{variables.$text-interactive}: #{common.$white}; /* Text on interactive colors */
//   #{variables.$text-caution}: #{common.$yellow}; /* Caution message text */
//   #{variables.$text-error}: #{common.$red-60}; /* Error message text */
//   #{variables.$text-success}: #{common.$green-60}; /* Success message text */
//   #{variables.$text-inverse}: #{common.$white}; /* Text on tooltip */

//   /* Interactive */
//   #{variables.$interactive-primary}: #{common.$blue-60}; /* Primary interactive color; Primary buttons */
//   #{variables.$interactive-secondary}: #{common.$grey-80}; /* Secondary interactive color; Secondary buttons */
//   #{variables.$focus}: #{common.$grey-60}; /* Checkbox Border */
//   #{variables.$interactive-04}: #{common.$blue-60}; /* Not currently used */
//   #{variables.$interactive-danger}: #{common.$red-60}; /* Danger button background; 3:1 AA contrast */
//   #{variables.$interactive-danger-ghost}: #{common.$red-60}; /* Danger button variant; Text; Icon; Border */

//   /* Links */
//   #{variables.$link-primary}: #{common.$blue-60}; /* Primary links */
//   #{variables.$link-secondary}: #{common.$blue-70}; /* Secondary link color for lower contrast backgrounds */
//   #{variables.$press-link}: #{common.$blue-80}; /* Link Pressed */
//   #{variables.$inverse-link}: #{common.$blue-40}; /* Link Color on $UI-05 background */

//   /* Icon */
//   #{variables.$icon-primary}: #{common.$grey-100}; /* Primary icons */
//   #{variables.$icon-tooltip}: #{common.$grey-90}; /* Tooltips */
//   #{variables.$icon-notam-pending}: #{common.$orange}; /* NAVAID Icons with pending NOTAMS */
//   #{variables.$icon-notam-active}: #{common.$red-60-hover}; /* NAVAID Icons with active NOTAMS */
//   #{variables.$icon-map-magenta}: #{common.$magenta}; /* Airport Icons, some NAVAIDS */
//   #{variables.$icon-map-cyan}: #{common.$cyan}; /* Some NAVAIDS */
//   #{variables.$icon-obstacle-notam}: #{common.$magenta-70}; /* Obstacles with NOTAMS */

//   /* Status */
//   #{variables.$info}: #{common.$blue-70}; /* Information */
//   #{variables.$success}: #{common.$green-60}; /* Success */
//   #{variables.$caution}: #{common.$yellow}; /* Caution */
//   #{variables.$error}: #{common.$red-60}; /* Error */

//   /* Focus */
//   #{variables.$focus}: #{common.$blue-60}; /* Focus border; Focus underline */

//   /* Hover */
//   #{variables.$hover-primary}: #{common.$blue-60-hover};
//   #{variables.$hover-secondary}: #{common.$grey-80-hover};
//   #{variables.$hover-tertiary}: #{common.$blue-60-hover};
//   #{variables.$hover-ui}: #{common.$grey-10-hover};
//   #{variables.$hover-danger}: #{common.$red-60-hover};
//   #{variables.$hover-map}: #{common.$grey-90-hover-90p}; // ?

//   /* Press */
//   #{variables.$press-primary}: #{common.$blue-80};
//   #{variables.$press-secondary}: #{common.$grey-80};
//   #{variables.$press-tertiary}: #{common.$grey-40};
//   #{variables.$press-ui}: #{common.$grey-70};
//   #{variables.$press-danger}: #{common.$red-80};
//   #{variables.$press-link}: #{common.$blue-50};
//   #{variables.$press-map}: #{common.$grey-70-90p};

//   /* Selected */
//   #{variables.$selected-ui}: #{common.$grey-87};
//   #{variables.$selected-01}: #{common.$grey-20};
//   #{variables.$selected-entry}: #{common.$grey-50}; /* Selected Data Entry */
//   #{variables.$selected-calendar}: #{common.$blue-80};
//   #{variables.$selected-tertiary}: #{common.$grey-50};
//   #{variables.$selected-secondary}: #{common.$blue-80}; /* In range calendar dates */
//   #{variables.$selected-primary}: #{common.$blue-60}; /* Selected calendar dates, toggle, Checkbox, and radio button */

//   /* Disabled */
//   #{variables.$disabled-01}: #{common.$grey-40};
//   #{variables.$disabled-02}: #{common.$grey-30};
//   #{variables.$disabled-03}: #{common.$grey-50};
//   #{variables.$disabled-map}: #{common.$grey-90-90p}; // ?

//   /* Transparent */
//   #{variables.$transparent}: #{common.$transparent};
// }
