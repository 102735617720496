@use "common";
@use "mixins";
@use "typography";
@use "theme-variables" as theme;

body {
  background: var(theme.$ui-background);
  color: var(theme.$text-primary);
  text-align: left;
  @extend %body-1;
}

hr {
  display: block;
  width: 100%;
  flex-basis: 100%;
  flex-shrink: 0;
  background: var(theme.$ui-divider);
  height: common.$spacing-01;
  margin-top: common.$spacing-05;
  margin-bottom: common.$spacing-05;
}

strong {
  font-weight: common.$font-weight-semibold;
}

em {
  font-style: italic;
}

pre {
  font-family: monospace;
  white-space: pre;
}

ul {
  list-style: disc;
}

a {
  @include mixins.link();
}

h1,
.h1 {
  @extend %h1;
}

h2,
.h2 {
  @extend %h2;
}

h3,
.h3 {
  @extend %h3;
}

h4,
.h4 {
  @extend %h4;
}

h5,
.h5 {
  @extend %h5;
}

.h5-semibold {
  @extend %h5-semibold;
}

h6,
.h6 {
  @extend %h6;
}

.body-1 {
  @extend %body-1;
}

.body-1-underline {
  @extend %body-1-underline;
}

.body-1-semibold {
  @extend %body-1-semibold;
}

.body-1-semibold-underline {
  @extend %body-1-semibold-underline;
}

.body-2 {
  @extend %body-2;
}

.body-2-semibold {
  @extend %body-2-semibold;
}

.body-3 {
  @extend %body-3;
}

.body-3-semibold {
  @extend %body-3-semibold;
}

.overline {
  @extend %overline;
}

.overline-semibold {
  @extend %overline-semibold;
}

.label {
  @extend %label;
}

.uom {
  @extend %uom;
}

.uom-semibold {
  @extend %uom-semibold;
}
