/* stylelint-disable fly-stylelint-plugin/no-direct-css-var-access */
* {
  --sb-track-color: var(--ui-background);
  --sb-thumb-color: var(--ui-inverse);
  --sb-size: .75rem;
}

*::-webkit-scrollbar {
  width: var(--sb-size);
}

*::-webkit-scrollbar-track {
  background: var(--sb-track-color);
  border-radius: 999px;
}

*::-webkit-scrollbar-thumb {
  background: var(--sb-thumb-color);
  border-radius: 999px;
  border: .25rem solid var(--ui-background);
}

@supports not selector(::-webkit-scrollbar) {
  * {
    scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
    scrollbar-width: thin;
  }
}
