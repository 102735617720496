$fade-anim: .2s ease !default;

.radial-menu-hide {
  visibility: hidden;
  /* stylelint-disable-next-line declaration-no-important */
  opacity: 0 !important;
  transition: opacity $fade-anim, visibility $fade-anim;
}

.radial-menu-show {
  visibility: visible;
  /* stylelint-disable-next-line declaration-no-important */
  opacity: .85 !important;
  transition: opacity $fade-anim;
}

// we want to keep the pointer events inside the drawn SVG (not its container)
/* stylelint-disable-next-line selector-max-id */
#radial-menu {
  pointer-events: none;

  // Safari bug fix: creates a new stacking context to prevent content overflow
  // from being clipped
  isolation: isolate;
}

// only allow pointer events with a `.radial-menu-show` ancestor (i.e. not during a drag)
.radial-menu-show .radial-menu-button-group {
  pointer-events: visible;
  cursor: pointer;
}

.radial-menu-drag {
  /* stylelint-disable-next-line declaration-no-important */
  opacity: .45 !important;
  transition: opacity $fade-anim;
}

/* stylelint-disable-next-line selector-max-id */
#radial-menu > #drag-shadow {
  visibility: hidden;
}

/* stylelint-disable-next-line selector-max-id */
.radial-menu-drag #radial-menu > #drag-shadow {
  visibility: visible;
}

$rot-anim: .3s ease !default;
$rot-deg: 55deg !default;

.radial-menu-buttons-hide {
  transform-origin: center;
  transform: rotate($rot-deg);
  transition: transform $rot-anim;
}

.radial-menu-buttons-show {
  transform-origin: center;
  transform: rotate(0deg);
  transition: transform $rot-anim;
}

/* stylelint-disable-next-line selector-max-id */
#radial-menu-info-ring {
  pointer-events: visible;
  cursor: default;
}

.radial-menu-info-hide {
  transform-origin: center;
  transform: rotate(-$rot-deg);
  transition: transform $rot-anim;
}

.radial-menu-info-show {
  transform-origin: center;
  transform: rotate(0deg);
  transition: transform $rot-anim;
}

text {
  font-weight: 500;
}

.radial-menu-button-label {
  fill: #fff;
  stroke: #000;
  stroke-width: 5;
  stroke-linejoin: round;
  paint-order: stroke;
}

.radial-menu-button {
  fill: #282927;
  stroke: #fff;
  stroke-width: 2;
}

.radial-menu-button.pressed {
  fill: #999;
}

/* stylelint-disable-next-line selector-max-id */
#aperture-outline {
  stroke: #000;
  stroke-width: 2;
  fill: transparent;
}

/* stylelint-disable-next-line selector-max-id */
.radial-menu-drag #thinking {
  visibility: visible;
  fill: transparent;
  stroke: #000;
  stroke-width: 2;
  transform-origin: center;
  animation: .4s infinite alternate pulse;
}

/* stylelint-disable-next-line selector-max-id */
#thinking {
  visibility: hidden;
}

@keyframes pulse {
  from {
    opacity: .4;
  }

  to {
    opacity: 1;
  }
}
